import Vue from 'vue';
import VueI18n from 'vue-i18n';
import api from './common/api.js';
import store from './common/store.js';
import { router } from './common/router.js';
import moment from 'moment';
import { RE_AUTHENTICATE } from './common/constants.js';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1].toLowerCase();
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getBrowserLanguage() {
	var userLang = navigator.language.slice(0,2) || navigator.userLanguage.slice(0,2);
	return userLang;
}

export function getUserLang() {
	if (window.localStorage.getItem("settings") && window.localStorage.getItem("access_token")) {
		return new Promise((resolve, reject) => {
			api.getUserInfo()
				.then(result => {
					resolve(
						api.getASUserSettings()
							.then(resultSetting => {
if (resultSetting.data && resultSetting.data.global && resultSetting.data.global.language) {
                  setUserLang(resultSetting.data.global.language);
                } else {
                  setUserLang("english");
                }								setDateLocale(),
								window.localStorage.setItem("userinfo", true),
								store.commit("user/setUserinfo", result.data),
								store.commit("user/setUserLoading", false),
								// Get user data
								api.getUser(result.data.sub)
									.then(resultSetting => {
										store.commit("user/setUserData", resultSetting.data)
									})
						}),

					);
				})
				.catch(error => {
					if (error.response) {
						store.commit("user/setUserLang", getBrowserLanguage())
						if (error.response.status === 401) {
							RE_AUTHENTICATE(error.response.status, error.response.data);
            			}
          			} else {
						store.commit("user/setUserLang", getBrowserLanguage());
						//in case or error, set browserlanguage as user language
						setUserLang(null);
						router.push("/Error"); //display a general error message to the user
						console.error({
							error: error
						});
            			reject(error);
          			}
      			})
		});
	} else {
		if (store) {
			store.commit("user/setUserLang", getBrowserLanguage());
		}
		else {
			getBrowserLanguage()
		}
	}
}

function setUserLang(lang) {
	let l=(lang===null) ? getBrowserLanguage(): lang;
	i18n.locale=l;
	if(store){
    store.commit("user/setUserLang", l);
    return
  }
}

function setDateLocale() {
	moment.locale(i18n.locale || i18n.fallbackLocale);
}

export function setFallbackLocale() {
	store.commit("user/setFallbackLocale", "en");
	return "en"
}

export const i18n = new VueI18n({
  locale: getUserLang(),
  fallbackLocale: store ? setFallbackLocale(): "en",
  messages: loadLocaleMessages(),
});